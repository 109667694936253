const onelink = "https://spartan2point0.onelink.me/XDQy";
const SpartanWeb = {
    SlickSliders: {
        slider18: {
            id: 18,
            settings: {
                slidesPerView: 1.05,
                spaceBetween: 20,
                centeredSlides: true,
                speed: 900,
                loop: true,
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                },
                autoplay: {
                    delay: 5500,
                    pauseOnMouseEnter: true,
                },
                breakpoints: {
                    768: {
                        spaceBetween: 145,
                        slidesPerView: 1.2,
                    },
                },
                modules: [null, null, null, null],
            },
        },
        slider19: {
            id: 19,
            settings: {
                modules: [null, null, null, null],
                centeredSlides: true,
                grabCursor: true,
                scrollbar: false,
                enabled: false,
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                },
                effect: "coverflow",
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    depth: 0,
                    modifier: 1,
                    slideShadows: false,
                },
                autoplay: {
                    delay: 2500,
                    pauseOnMouseEnter: true,
                },
                loop: true,
                slidesPerView: "1.1",
                spaceBetween: 20,
                breakpoints: {
                    768: {
                        slidesPerView: "auto",
                        coverflowEffect: {
                            rotate: 0,
                            stretch: 25,
                            depth: 50,
                            modifier: 12,
                            slideShadows: false,
                        },
                    },
                },
            },
        },
        sliderCaptions: {
            id: "captions",
            settings: {
                slidesPerView: 1,
                direction: "vertical",
                speed: 900,
                centeredSlides: true,
                grabCursor: false,
                allowTouchMove: false,
                loop: true,
                autoplay: {
                    delay: 800,
                },
                modules: [null, null, null, null],
            },
        },
    },
};
const GO = {};
if (
    window.__IS_LOGIN === "true"
        ? (GO.userLoggedIn = true)
        : (GO.userLoggedIn = false)
);
import(/* webpackChunkName: "jQChunk" */ "jquery").then(({ default: jQ }) => {
    /* 
  |-----------
  |Init JQUERY and Load Bootstrap
  |-----------
  */
    window.$ = window.jQuery = jQ;
    import(/* webpackChunkName: "boostrapVenChunk" */ "bootstrap");
    import(/* webpackChunkName: "web_main" */ "./main_old").then(
        ({ default: mainer }) => {
            window.sp = mainer.sp;
            window.buyChips = mainer.buyChips;
            window.myAccount = mainer.myAccount;
            window.myProfile = mainer.myProfile;
            window.checkToken = mainer.checkToken;
            window.getCookie = mainer.getCookie;
            window.logout = mainer.logout;
            window.popup = mainer.popup;
            window.setCookie = mainer.setCookie;
            window.detectEvent = mainer.detectEvent;
            window.openCashier = mainer.openCashier;
            window.openMyProfile = mainer.openMyProfile;
            window.openOffers = mainer.openOffers;
            window.openTransactions = mainer.openTransactions;
            window.openWithdraw = mainer.openWithdraw;
        },
    );

    /* 
  |-----------
  |Temp solution to import main.js
  |-----------
  */
    // const script = document.createElement('script');
    // script.src = '/theme1/js/main.js';
    // document.body.appendChild(script);

    /* 
  |-----------
  |Load Swiper JS
  |-----------
  */
    const SwiperSlider = (window.SwiperSlider = require("swiper"));

    let defSwiperSettingObject = {
        modules: [
            SwiperSlider.Pagination,
            SwiperSlider.Autoplay,
            SwiperSlider.Navigation,
            SwiperSlider.EffectCoverflow,
        ],
    };

    for (const carouselle in SpartanWeb.SlickSliders) {
        if (Object.hasOwnProperty.call(SpartanWeb.SlickSliders, carouselle)) {
            const element = SpartanWeb.SlickSliders[carouselle];
            setObj = element.settings;
            Object.assign(setObj, defSwiperSettingObject);
            let thisPageswiper = new SwiperSlider.Swiper(
                ".slider-" + element.id,
                setObj,
            );
            console.log(thisPageswiper);
        }
    }

    setTimeout(() => {
        import(/* webpackChunkName: "swipeChunk" */ "./swiperInit").then(
            ({ default: allSw }) => {
                return;
            },
        );
    }, 900);

    //Load Swiper JS

    // Load Lazysizes JS
    function lazyImages() {
        return import(
            /* webpackChunkName: "lazySizesChunk" */ "./lazysizes.min"
        ).then(() => {
            console.log("Lazy Sizes JS Loaded");
        });
    }
    lazyImages();

    /* 
  |-----------
  |on Page Load
  |-----------
  */

    jQuery(function () {
        $(".main-right-bar").addClass("page-loaded");

        $("#login-modal").on("shown.bs.modal", function (e) {
            setTimeout(() => {
                if ($("body").hasClass("modal-open")) {
                    return;
                } else {
                    $("body").addClass("modal-open");
                }
            }, 500);
        });

        setTimeout(() => {
            $(".main-right-bar.page-loaded").css({
                transition: "transform 250ms ease-in-out",
            });
        }, 900);

        function showRightBar(e) {
            e.preventDefault();
            document
                .querySelector("aside.main-right-bar")
                .classList.add("show");
            document.querySelector(".body-cover").classList.add("appear");
            document.body.style.overflow = "hidden";
        }

        function hideRightBar(e) {
            e.preventDefault();
            document
                .querySelector("aside.main-right-bar")
                .classList.remove("show");
            document.querySelector(".body-cover").classList.remove("appear");
            document.body.style.overflow = "auto";
            document.body.style.overflowX = "hidden";
        }

        $("body").on("click", ".body-cover.appear", function (e) {
            // ON CLICKING OUTSIDE Right Bar
            hideRightBar(e);
        });

        $(".navbar-toggler").on("click", function (e) {
            // Show Nav on clicking the hamburger
            showRightBar(e);
        });

        document
            .querySelector(".header-back")
            .addEventListener("click", hideRightBar); // Back Button on the right bar

        let navItems = $(".main-nav-list > .main-nav-item > .main-nav-link"); // Click on outer nav items
        navItems.each(function (index, elem) {
            elem.addEventListener(
                "click",
                function (e) {
                    navItems.removeClass("up");
                    e.preventDefault();
                    thisSubMenu = $(e.target).siblings(".sub-menu");
                    if (thisSubMenu.hasClass("show")) {
                        thisSubMenu.removeClass("show");
                    } else {
                        $(".sub-menu").removeClass("show");
                        $(thisSubMenu).addClass("show");
                        $(e.target).addClass("up");
                    }
                },
                true,
            );
        });

        // $("#signup-modal").on('show.bs.modal',function(){ // On clicking the Signup Button
        //   if(!jQuery.validator){
        //     $.getScript("https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.5/jquery.validate.min.js",function(){
        //       import(/* webpackChunkName: "registrationChunk" */'../../../../public/js/registration').then(()=>{
        //         console.log("RegJS Pulled in");
        //       })
        //     })
        //   }else{
        //     import(/* webpackChunkName: "registrationChunk" */'../../../../public/js/registration').then(()=>{
        //         console.log("RegJS Pulled in");
        //       })
        //   }
        // });

        //Login Popup
        // $(".login-now").on("click", function (e) { // Clicking Login Now on SignUp Popup
        //   e.preventDefault();
        //   $("#signup-modal").modal("hide");
        //   $("#login-modal").modal("show");
        //   $("input").removeClass("is-invalid");
        //   $(".error").html("");
        //   $(".form-error").html("");
        // });

        // $(".signup-now").on('click',function(e){
        //   e.preventDefault();
        //   $(".modal.show").modal('hide');
        //   $("#signup-modal").modal('show');
        // })

        // /*
        // |-----------
        // |Appsflyer Link Provision
        // |-----------
        // */
        $.getScript("/js/onelink-smart-script.js", function () {
            import(/* webpackChunkName: "aFChunk" */ "./appsflyerGenerate");
        });

        function checkoutSMSForm() {
            function sendLinkTimer(elem, numberOfSeconds) {
                var countDownDate;
                countDownDate = new Date().getTime() + numberOfSeconds * 1000;
                GO.timerId = setInterval(function () {
                    var now = new Date().getTime();
                    var distance = parseInt(countDownDate) - now;
                    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
                    var hours = Math.floor(
                        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
                    );
                    var minutes = Math.floor(
                        (distance % (1000 * 60 * 60)) / (1000 * 60),
                    )
                        .toString()
                        .padStart(2, "0");
                    var seconds = Math.floor((distance % (1000 * 60)) / 1000)
                        .toString()
                        .padStart(2, "0");
                    elem.html(`${minutes} : ${seconds}`);
                    if (distance < 0) {
                        clearInterval(GO.timerId);
                        elem.removeAttr("disabled");
                        elem.html("Get Link");
                    }
                }, 900);
            }

            let inlineForms = ["#banner-sms-form", "#community-form"];
            inlineForms.forEach(function (elF, elFIndex) {
                // $("#banner-sms-form").validate({
                $(elF).validate({
                    rules: {
                        phone: {
                            required: true,
                            digits: true,
                            minlength: 10,
                            maxlength: 10,
                        },
                    },
                    messages: {
                        phone: {
                            required: "Please enter a valid mobile no.",
                            digits: "Please enter 10 numeric digits",
                        },
                    },
                    errorElement: "p",
                    errorClass: "text-danger text-left mb-0 sms-form-error",
                    errorPlacement: function (error, element) {
                        if (
                            $(element).attr("name") == "phone" ||
                            $(element).attr("name") == "otp"
                        ) {
                            // Placing Error Message at the valid position
                            if (
                                element
                                    .parent()
                                    .parent()
                                    .parent()
                                    .attr("name") == "community-form"
                            ) {
                                error.insertAfter(element);
                            } else {
                                error.appendTo(
                                    element.parent().parent().parent().parent(),
                                );
                            }
                        } else {
                            error.insertAfter(element);
                        }
                    },
                    submitHandler: function (form, event) {
                        let thisform = $(form);
                        let actionUrl = thisform.attr("action");
                        submitButton = thisform.find("[type='submit'");

                        $.ajax({
                            type: thisform.attr("method"),
                            url: actionUrl,
                            data: thisform.serialize(),
                            beforeSend: function () {
                                submitButton.attr("disabled", true);
                                submitButton.html(
                                    `<i class="fa-solid fa-spin fa-spinner">`,
                                );
                                // form.addClass("activeLoading").prop("disabled", true);
                            },
                            // serializes the form's elements.
                            success: function (response) {
                                if (response.status) {
                                    sendLinkTimer(submitButton, 30);
                                    alert("Download Link Sent to your phone");
                                    $("#install-modal").modal("show");
                                } else {
                                    alert(
                                        "Something went wrong, please try again later",
                                    );
                                    submitButton.html(`Get Link`);
                                    submitButton.removeAttr("disabled");
                                }
                            },
                            failure: function () {
                                submitButton.html(`Get Link`);
                                submitButton.removeAttr("disabled");
                                alert(
                                    "Something went wrong, please try again later",
                                );
                            },
                        });
                    },
                });
            });
        }

        if (window.innerWidth > 768) {
            if (!jQuery.validator) {
                $.getScript(
                    "https://cdnjs.cloudflare.com/ajax/libs/jquery-validate/1.19.5/jquery.validate.min.js",
                    checkoutSMSForm,
                );
            } else {
                checkoutSMSForm();
            }
        }

        /* 
    |-----------
    |UTM Attribution Session Maintenance
    |-----------
    */
        var queryForm = function (settings) {
            var reset = settings && settings.reset ? settings.reset : false;
            var self = window.location.toString();
            var querystring = self.split("?");
            if (querystring.length > 1) {
                var pairs = querystring[1].split("&");
                for (i in pairs) {
                    var keyval = pairs[i].split("=");
                    if (reset || sessionStorage.getItem(keyval[0]) === null) {
                        sessionStorage.setItem(
                            keyval[0],
                            decodeURIComponent(keyval[1]).replace(/[+#]/g, " "),
                        );
                    }
                }
            }
            var hiddenFields = document.querySelectorAll(
                "input[type=hidden], input[type=text]",
            );
            for (var i = 0; i < hiddenFields.length; i++) {
                var param = sessionStorage.getItem(hiddenFields[i].name);
                if (param)
                    document.getElementsByName(hiddenFields[i].name)[0].value =
                        param;
            }
        };
        setTimeout(function () {
            queryForm({ reset: true });
        }, 3000);
        // UTM Attribution Session Maintenance Ends

        /* 
    |-----------
    |Freshworks: Freshchat Chat Script
    | - previously Salesforce Chat
    |-----------
    */
        // window.fcSettings = {
        //     onInit: function () {
        //         if (GO.userLoggedIn) {
        //             let thisUserData = JSON.parse(window.__USER_DATA);
        //             console.log("USER IS LOGGED IN");
        //             console.log(thisUserData);

        //             // var restoreId = RESTOREID //Which need to be fetched from your DB
        //             window.fcWidgetMessengerConfig = {
        //                 externalId: thisUserData.userId, // user's id unique to your system
        //                 // restoreId: restoreId ? restoreId : null
        //             };
        //             window.fcWidget.user.get(function (resp) {
        //                 var status = resp && resp.status,
        //                     data = resp && resp.data;
        //                 if (status !== 200) {
        //                     window.fcWidget.user.setProperties({
        //                         firstName:
        //                             thisUserData.firstName ||
        //                             thisUserData.userName, // user's first name
        //                         lastName:
        //                             thisUserData.lastName ||
        //                             thisUserData.userName, // user's last name
        //                         email: thisUserData.email, // user's email address
        //                         phone: thisUserData.mobile || "", // phone number without country code
        //                         status: "Active", // user's meta property 2
        //                     });
        //                     window.fcWidget.on("user:created", function (resp) {
        //                         var status = resp && resp.status,
        //                             data = resp && resp.data;
        //                         if (status === 200) {
        //                             console.log(resp.data);
        //                             // if (data.restoreId) {
        //                             //   // Update restoreId in your database
        //                             // }
        //                         }
        //                     });
        //                 }
        //             });
        //         } else {
        //             console.log("USER IS NOT LOGGED IN");
        //         }
        //     },
        // };

        $("#helpButtonEnabled").on("click", function (e) {
            if (window.fcWidget) {
                window.fcWidget.open();
            }
            // FreshworksWidget('open', 'ticketForm');
            // import(/* webpackChunkName: "salesChatChunk" */'./salesForceChat').then(()=>{
            //   if($(this).attr('id') == 'helpButtonEnabled'){
            //     e.stopPropagation();
            //     let chatButton = $(this);
            //     $(this).children('img').fadeOut();
            //     chatButton.addClass('rounded btn btn-dark btn-lg');
            //     chatButton.append(`<i class="fas fa-spin fa-spinner text-white"></i>`);
            //   }
            //   if($('.helpButton').length){
            //     $(".helpButtonEnabled").trigger('click');
            //   }else{
            //     let clicker = setInterval(() => {
            //       if($('.helpButton').length){
            //         $(".helpButtonEnabled").trigger('click');
            //         clearInterval(clicker);
            //       }
            //     }, 1000);
            //   }
            // });
        });
        // Freshworks/Freshchat Chat Script Ends

        // Check URL Para for login or registration
        var urlName = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });
        if (urlName.targetPageId != null) {
            var urlParam = urlName.targetPageId.toLowerCase();
            if (urlParam == "registration") {
                $("#login-modal").modal("hide");
                $("#signup-modal").modal("show");
                $("input").removeClass("is-invalid");
                $(".error").html("");
                $(".form-error").html("");
                if (urlName.utm_medium != null || urlName.utm_medium != "") {
                    $("input[name=promo]").prop("checked", true);
                    $("input[name=promoCode]").removeClass("d-none");
                    $("input[name=promoCode]").val(urlName.utm_medium);
                }
            }
        }
        if (urlName.targetPageId != null) {
            var urlParams = urlName.targetPageId.toLowerCase();
            if (urlParams == "login") {
                $("#signup-modal").modal("hide");
                $("#login-modal").modal("show");
                $("input").removeClass("is-invalid");
                $(".error").html("");
                $(".form-error").html("");
            }
        }

        if (GO.userDetails) {
            // Import Code for logged in user only when user's logged in
            import(
                /* webpackChunkName: "loggedInUserChunk" */ "./loggedInUser"
            );
        }

        if (GO.userDetails) {
            return;
        } else {
            $("#phone-nav .download-apps").on("click", function (e) {
                e.preventDefault();
                if ($(this).hasClass("download-apps")) {
                    $("#install-modal").modal("show");
                    location.assign($(this).attr("href"));
                }
            });
        }
    });
    // On Page Load Ends
});
